import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';
import { produce } from 'immer';

const INITIAL_STATE = {
  [AT.UPDATE_HOME]: Remote.NotAsked,
};

const uiReducer = produce((draft, action) => {
  switch (action.type) {
    case loading(AT.UPDATE_HOME):
      draft[AT.UPDATE_HOME] = Remote.Loading;
      break;

    case success(AT.UPDATE_HOME):
      draft[AT.UPDATE_HOME] = Remote.Success(true);
      draft.accumulate = action.payload.totalCount;
      draft.quickSaleGoods = action.payload.quickSaleGoods;
      draft.dawinCareGoods = action.payload.dawinCareGoods;
      draft.banner = {
        mainStaticBanner: action.payload?.mainStaticBanner?.data,
        // mainRollingBanner: action.payload.mainRollingBanner.data,
      };
      break;

    case failure(AT.UPDATE_HOME):
      draft[AT.UPDATE_HOME] = Remote.Failure(action.error);
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default uiReducer;
