import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import loading from './loading/reducers';
import { getAuth } from './auth/reducers';
import { getBuying } from './buying/reducers';
import selling from './selling/reducers';
import common from './common/reducers';
import uploadImage from './uploadImage/reducers';
import myPage from './myPage/reducers';
import brokerPage from './brokerPage/reducers';
import rating from './rating/reducers';
import regQNA from './regQNA/reducers';
import agentSignUp from './agentSignUp/reducers';
import analysis from './analysis/reducers';
import newsale from './newsale/reducers';
import my from './my/reducers';
import personalize from './personalize/reducers';
import address from './address/reducers';
import community from './community/reducers';
import chat from './chat/reducers';
import user from './user/reducers';
import resource from './resource/reducers';
import payment from './payment/reducers';
import app from './app/reducers';
import academy from './academy/reducers';
import ui from './ui/reducers';
import review from './review/reducers';
import dawincare from './dawinCareGoods/reducers';

const createRootReducer = (history, initialData) =>
  combineReducers({
    router: connectRouter(history),
    auth: getAuth(initialData),
    buying: getBuying(initialData),
    selling,
    loading,
    common,
    uploadImage,
    myPage,
    brokerPage,
    rating,
    regQNA,
    agentSignUp,
    analysis,
    newsale,
    personalize,
    my,
    address,
    community,
    chat,
    user,
    resource,
    payment,
    app,
    academy,
    ui,
    review,
    dawincare,
  });

export { createRootReducer };
