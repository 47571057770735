import { put } from 'redux-saga/effects';
import { actions } from '..';
import apis from '@/apis';
import { STATUS } from '../utils';

export function* getDawinCareGoods({ payload }) {
  const { origin, setter } = payload || {};

  try {
    // -- 더보기 클릭시에는  ThreeDots, 전체 조회에는 skeleton 보여줌 -- //
    setter && setter(STATUS.LOADING);

    // -- 다윈케어 매물 조회 -- //
    const result = yield apis.buyingApi.getDawinCareGoods({});
    // -- 다윈케어 reducer에 최종값 저장 -- //
    yield put(actions.dawincare.setDawinCareGoods({ origin, data: result?.list, total: result?.list?.length }));

    setter && setter(STATUS.SUCCESS);
  } catch (e) {
    setter && setter(STATUS.FAILURE);
    console.error(e);
  }
}
