import * as AT from './actionTypes';

export const getDawinCareGoods = (payload) => ({
  type: AT.GET_DAWINCARE_GOODS,
  payload,
});

export const setDawinCareGoods = (payload) => ({
  type: AT.SET_DAWINCARE_GOODS,
  payload,
});

export const resetDawinCareGoods = () => ({
  type: AT.RESET_DAWINCARE_GOODS,
});
