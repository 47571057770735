import * as AT from './actionTypes';
const INITIAL_STATE = {
  dawinCareGoods: {
    origin: '',
    data: [],
    total: 0,
  },
};

export default function dawinCareGoods(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_DAWINCARE_GOODS: {
      const { origin, data, total } = action.payload || {};
      return { ...state, dawinCareGoods: { origin, total, data: [...state.dawinCareGoods.data, ...data] } };
    }
    case AT.RESET_DAWINCARE_GOODS:
      return { ...state, dawinCareGoods: INITIAL_STATE.dawinCareGoods };
    default:
      return { ...state };
  }
}
