import apis from '@/apis';
import { actions } from '@/data';
import React from 'react';
import { STATUS } from '@/data/utils';
import { store } from '@/data/configureStore';
import { getURLPrefix } from '@/pages/Sale/helpers';

/**
 * 매물 상태 변경
 * goodsStatus: 변경 할 매물 상태 코드
 * setStatus: loading, success, failure
 * setGoodsState
 */
export const updateGoodsStatus = async ({ goods_common_idx, goodsStatus, setStatus, onSuccess, afterSucessCallback }) => {
  try {
    setStatus({ status: STATUS.LOADING });
    const result = await apis.sellingApi.updateGoodsStatus(goods_common_idx, { goods_status: goodsStatus });

    // 상태 업데이트 후 실행할 함수
    onSuccess && onSuccess(result.to_be_status);
    afterSucessCallback && afterSucessCallback();

    setStatus({ status: STATUS.SUCCESS });
  } catch (e) {
    setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

/**
 * isRealtorRegistered는 중개사의 매물등록 페이지 (/brokerPage/mng_goods_register)에서 거래완료 케이스를 처리하기 위해 추가함
 */
export const updateGoodsStatus2 = async ({
  isRealtorRegistered = false,
  goods_common_idx,
  currentGoodsStatus,
  toBeGoodsStatus,
  setStatus,
  contract_info,
  onSuccess,
  afterSucessCallback,
}) => {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });

    const result = contract_info
      ? await apis.sellingApi.updateGoodsStatus(goods_common_idx, { goods_status: toBeGoodsStatus, contract_info })
      : await apis.sellingApi.updateGoodsStatus(goods_common_idx, { goods_status: toBeGoodsStatus });

    // 상태 업데이트 후 실행할 함수
    if (result && onSuccess) {
      if (isRealtorRegistered) {
        onSuccess(
          actions.common.alert({
            contents: '매물상태가 변경되어 더 이상 이용자들에게 게시되지 않습니다. [계약관리] 메뉴에서 계약체결내역을 등록해주세요.',
          })
        );
        onSuccess(actions.myPage.resetMySellList());
        onSuccess(actions.myPage.getMySellList({ limit: 10, offset: 0 }));
      } else {
        onSuccess(actions.myPage.getMySellList2({ limit: 10, offset: 0, goodsStatus: currentGoodsStatus, setStatus }));
      }
      afterSucessCallback && afterSucessCallback();
    }

    setStatus && setStatus({ status: STATUS.SUCCESS });
  } catch (e) {
    setStatus ** setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const deleteGoods = async ({ goods_common_idx, setStatus, goodsStatus, onSuccess, callback }) => {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    const result = await apis.sellingApi.deleteGoods(goods_common_idx);
    // const test = () =>{
    //   console.log('TEST')
    // actions.myPage.getMySellList2({ limit: 10, offset: 0, goodsStatus, setStatus });
    //   actions.myPage.getMySellCount([]);
    // }

    // 상태 업데이트 후 실행할 함수
    onSuccess && onSuccess(actions.myPage.getMySellList2({ limit: 10, offset: 0, goodsStatus, setStatus }));
    // onSuccess && onSuccess(test());
    // console.log(result, '삭제완료')
    setStatus && setStatus({ status: STATUS.SUCCESS });
    callback && callback();
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const updateBroker = async ({ goods_common_idx, brokerData, setStatus }) => {
  try {
    setStatus({ status: STATUS.LOADING });
    const result = await apis.sellingApi.updateBrokersByGoods(goods_common_idx, brokerData);
    setStatus({ status: STATUS.SUCCESS, data: result });
  } catch (e) {
    setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getBrokersByGoodsPosition = async ({ goods_common_idx, setStatus, setResult }) => {
  try {
    setStatus({ status: STATUS.LOADING });
    const result = await apis.sellingApi.getBrokersByGoodsPosition(goods_common_idx);
    setResult(result);
    setStatus({ status: STATUS.SUCCESS });
  } catch (e) {
    setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getBrokersByGoods = async ({ goods_common_idx, setResult }) => {
  try {
    const result = await apis.sellingApi.getBrokersByGoods(goods_common_idx);
    setResult(result);
  } catch (e) {
    console.error(e.message);
  }
};

export const getMembershipBrokersByGoods = async ({ goods_common_idx, setResult }) => {
  try {
    const result = await apis.sellingApi.getMembershipBrokersByGoods(goods_common_idx);
    setResult(result);
  } catch (e) {
    console.error(e.message);
  }
};

export const getDawinCareInfoByGoods = async ({ goods_common_idx, setResult }) => {
  try {
    const result = await apis.sellingApi.getDawinCareInfoByGoods(goods_common_idx);
    setResult(result);
  } catch (e) {
    console.error(e.message);
  }
};

export const addBrokersByGoods = async ({ goods_common_idx, data, setStatus }) => {
  try {
    setStatus({ status: STATUS.LOADING });
    const result = await apis.sellingApi.updateBrokersByGoods(goods_common_idx, data);
    setStatus({ status: STATUS.SUCCESS, data: result });
  } catch (e) {
    setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const updateBrokersByGoods = async ({ goods_common_idx, addList, deleteList, setStatus, updateStatus }) => {
  const { dispatch } = store;

  try {
    setStatus({ status: STATUS.LOADING });
    if (addList?.length > 0) {
      await apis.sellingApi.updateBrokersByGoods(goods_common_idx, { broker_list: addList });
    }
    if (deleteList?.length > 0) {
      await apis.sellingApi.deleteBrokersByGoods(goods_common_idx, deleteList?.join(','));
    }
    if (updateStatus) {
      await apis.sellingApi.updateGoodsStatus(goods_common_idx, { goods_status: 'SB' });
      dispatch(actions.selling.updateSavedForm({ goods_status: 'SB' }));
    }
    setStatus({ status: STATUS.SUCCESS });
  } catch (e) {
    setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getOptionsByType = async (data, setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.sellingApi.getOptionsByType(data);
    setData({ status: STATUS.SUCCESS, data: result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

export const createGoodsMemo = async ({ tb_cb_complex_idx, reason, setStatus, setGoodsState }) => {
  try {
    // setStatus({ status: STATUS.LOADING });
    // console.log(tb_cb_complex_idx, reason)
    const result = await apis.sellingApi.createGoodsMemo(tb_cb_complex_idx, { contents: reason });
    // setData({ status: STATUS.SUCCESS, data: result });
    if (result) {
      const response = await apis.sellingApi.updateGoodsStatus(tb_cb_complex_idx, { goods_status: 'TR' });
      setGoodsState(response.to_be_status);
    }
  } catch (e) {
    console.error(e);
    // setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const createGoodsMemo2 = async ({ tb_cb_complex_idx, reason, setStatus, itemStatus, dispatch, afterSucessCallback }) => {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });

    const result = await apis.sellingApi.createGoodsMemo(tb_cb_complex_idx, { contents: reason });
    if (result) {
      const response = await apis.sellingApi.updateGoodsStatus(tb_cb_complex_idx, { goods_status: 'TR' });
      if (response) {
        dispatch(
          actions.common.alert({
            contents: (
              <>
                거래 중지로 변경
                <br />
                매물상태가 변경이 되어 더 이상 이용자들에게 게시되지 않습니다.
              </>
            ),
            onConfirm: () => {
              dispatch(actions.myPage.getMySellList2({ limit: 10, offset: 0, goodsStatus: itemStatus, setStatus }));
              dispatch(actions.myPage.getMySellCount([]));
              afterSucessCallback && afterSucessCallback();
            },
          })
        );
      }
    }
    setStatus && setStatus({ status: STATUS.SUCCESS });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const registerAgainGood = async ({ goods_common_idx, goods_name }) => {
  const { dispatch } = store;

  try {
    // 기존 매물 데이터를 추출해서
    const exsitedData = await apis.sellingApi.getGoods(goods_common_idx);
    // 파라미터 안들어가는 것들 삭제
    delete exsitedData.goods_common_idx;
    delete exsitedData.create_date;
    delete exsitedData.update_date;
    delete exsitedData.goods_status;
    delete exsitedData.member;
    delete exsitedData.enter_date;
    delete exsitedData.jibun;
    delete exsitedData.lat;
    delete exsitedData.lng;
    delete exsitedData.mount_yn;
    delete exsitedData.total_house_hold_count;

    // 가격 파라미터에 맞는 형식으로 변환하는 로직
    let tmp = [];
    const isExistPrice = (priceType) => exsitedData.goods.findIndex((g) => g.trade_type === priceType);
    if (isExistPrice('s') >= 0) {
      tmp.push({ trade_type: 's', price1: exsitedData.goods[isExistPrice('s')].price1 });
    }
    if (isExistPrice('c') >= 0) {
      tmp.push({ trade_type: 'c', price2: exsitedData.goods[isExistPrice('c')].price2 });
    }
    if (isExistPrice('m') >= 0) {
      tmp.push({ trade_type: 'm', price2: exsitedData.goods[isExistPrice('m')].price2, price3: exsitedData.goods[isExistPrice('m')].price3 });
    }

    //이미지 인덱스 삭제
    if (exsitedData.image.length > 0) {
      exsitedData.image.map((i) => delete i.goods_image_idx);
    }

    exsitedData.goods = tmp;
    exsitedData.complex_name = goods_name;
    if (exsitedData) {
      const data = await apis.sellingApi.createGoods(exsitedData);
      if (data) {
        dispatch(actions.router.push(`/selling/manual/default/${data.goods_common_idx}`));
      } else {
        console.error('새 매물 생성 실패');
      }
    } else {
      console.error('기존 데이터가 존재하지 않습니다.');
    }
  } catch (e) {
    console.error(e);
  }
};

export const updatePrice = async ({ goods_common_idx, setStatus, tobePrice, onSuccessCallback }) => {
  const { dispatch } = store;
  try {
    setStatus({ status: STATUS.LOADING });
    const targetData = await apis.sellingApi.getGoods(goods_common_idx);
    const updatePrice = { ...targetData, goods: tobePrice?.goods, option: tobePrice?.option };
    const data = await apis.sellingApi.updateGoods(goods_common_idx, updatePrice);
    if (data) {
      setStatus({ status: STATUS.SUCCESS });

      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    } else {
      console.error('error..');
    }
  } catch (e) {
    dispatch(
      actions.common.alert({
        contents: <>{e.message}</>,
      })
    );
    setStatus({ status: STATUS.FAILURE, error: e.message });
  }
};

export const deleteExtraInfo = ({ originalData, goods_name }) => {
  // delete originalData.goods_common_idx;
  delete originalData.create_date;
  delete originalData.update_date;
  delete originalData.goods_status;
  delete originalData.member;
  delete originalData.enter_date;
  delete originalData.lat;
  delete originalData.lng;
  delete originalData.total_house_hold_count;

  // 가격 파라미터에 맞는 형식으로 변환하는 로직
  let goodsData = [];
  const isExistPrice = (priceType) => originalData.goods.findIndex((g) => g.trade_type === priceType);
  if (isExistPrice('s') >= 0) {
    goodsData.push({ trade_type: 's', price1: originalData.goods[isExistPrice('s')].price1 });
  }
  if (isExistPrice('c') >= 0) {
    goodsData.push({ trade_type: 'c', price2: originalData.goods[isExistPrice('c')].price2 });
  }
  if (isExistPrice('m') >= 0) {
    goodsData.push({ trade_type: 'm', price2: originalData.goods[isExistPrice('m')].price2, price3: originalData.goods[isExistPrice('m')].price3 });
  }
  originalData.goods = goodsData;
  originalData.complex_name = goods_name;

  return originalData;
};

export const getInfoAndTrimData = async ({ goods_common_idx, goods_name }) => {
  let originalData;
  try {
    // GET 기존 매물 가져오기
    originalData = await apis.sellingApi.getGoods(goods_common_idx);
    // 기존 매물에서 필요없는 정보 delete
    originalData = deleteExtraInfo({ originalData, goods_name });
  } catch (error) {
    throw new Error('매물이 정상적으로 등록되지 않았습니다.');
  }
  return originalData;
};

export const createNewGood = async ({ trimData }) => {
  let newGoodData;
  try {
    // CREATE 재등록
    newGoodData = await apis.sellingApi.createGoods(trimData);
  } catch (error) {
    throw new Error('매물이 정상적으로 등록되지 않았습니다.');
  }

  return newGoodData;
};

export const updateGoodStatus = async ({ newGoodData, dispatch, setMySellList }) => {
  try {
    // 매물 상태 업데이트
    await apis.sellingApi.updateGoodsStatus(newGoodData?.goods_common_idx, { goods_status: 'SB' });

    const URL_PREFIX = getURLPrefix();
    dispatch(actions.router.push(`${URL_PREFIX}/complete`));
    setMySellList && setMySellList({ status: STATUS.SUCCESS });
  } catch (error) {
    if (error?.message?.indexOf('같은 매물') !== -1) {
      throw new Error('입력하신 매물과 같은 동/호수 및 거래유형 매물이 다윈중개 서비스에 게시중입니다.');
    } else {
      throw new Error(error);
    }
  }
};
