import authSagas from '@/data/auth/sagaRegister';
import commonSagas from '@/data/common/sagaRegister';
import buyingSagas from '@/data/buying/sagaRegister';
import sellingSagas from '@/data/selling/sagaRegister';
import uploadImageSagas from '@/data/uploadImage/sagaRegister';
import myPageSagas from '@/data/myPage/sagaRegister';
import brokerPageSagas from '@/data/brokerPage/sagaRegister';
import ratingSagas from '@/data/rating/sagaRegister';
import regQNASagas from '@/data/regQNA/sagaRegister';
import agentSignUp from '@/data/agentSignUp/sagaRegister';
import analysis from '@/data/analysis/sagaRegister';
import newsale from '@/data/newsale/sagaRegister';
import personalize from '@/data/personalize/sagaRegister';
import my from '@/data/my/sagaRegister';
import address from '@/data/address/sagaRegister';
import community from '@/data/community/sagaRegister';
import chat from '@/data/chat/sagaRegister';
import resource from '@/data/resource/sagaRegister';
import payment from '@/data/payment/sagaRegister';
import academy from '@/data/academy/sagaRegister';
import ui from '@/data/ui/sagaRegister';
import review from '@/data/review/sagaRegister';
import { all, fork } from 'redux-saga/effects';
import dawincare from '@/data/dawinCareGoods/sagaRegister';

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(commonSagas),
    fork(buyingSagas),
    fork(sellingSagas),
    fork(uploadImageSagas),
    fork(myPageSagas),
    fork(brokerPageSagas),
    fork(ratingSagas),
    fork(regQNASagas),
    fork(agentSignUp),
    fork(analysis),
    fork(newsale),
    fork(personalize),
    fork(my),
    fork(address),
    fork(community),
    fork(chat),
    fork(resource),
    fork(payment),
    fork(academy),
    fork(ui),
    fork(review),
    fork(dawincare),
  ]);
}
